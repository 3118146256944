@font-face {
    font-family: 'behaRegular';
    src: url('../fonts/behaRegular.eot');
    src: url('../fonts/behaRegular.eot?#iefix') format('embedded-opentype'),
        url('../fonts/behaRegular.woff2') format('woff2'),
        url('../fonts/behaRegular.woff') format('woff'),
        url('../fonts/behaRegular.ttf') format('truetype');
    font-weight: 300;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'behaBold';
    src: url('../fonts/behaBold.eot');
    src: url('../fonts/behaBold.eot?#iefix') format('embedded-opentype'),
        url('../fonts/behaBold.woff2') format('woff2'),
        url('../fonts/behaBold.woff') format('woff'),
        url('../fonts/behaBold.ttf') format('truetype');
    font-weight: bold;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'behaRegular2';
    src: url('../fonts/behaRegular2.eot');
    src: url('../fonts/behaRegular2.eot?#iefix') format('embedded-opentype'),
        url('../fonts/behaRegular2.woff2') format('woff2'),
        url('../fonts/behaRegular2.woff') format('woff'),
        url('../fonts/behaRegular2.ttf') format('truetype');
    font-weight: bold;
    font-style: normal;
    font-display: swap;
}

*,
::after,
::before {
    box-sizing: border-box;
}

* {
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

body {
    font-family: 'behaBold';
    font-size: 16px;
    font-weight: 400;
    line-height: 1.7;
    background-color: #faf0e4;
    color: #111111;
    word-wrap: break-word;
    padding: 0px;
    margin: 0px;
    text-align: center;

    &::after {
        content: "";
        position: fixed;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background-image: url(../images/bgbody.png);
        background-position: center;
        background-size: auto;
        background-repeat: repeat;
        z-index: -2;
        filter: brightness(10);
    }
}

img {
    max-width: 100%;
    -webkit-transition: all .3s ease-out 0s;
    -moz-transition: all .3s ease-out 0s;
    -ms-transition: all .3s ease-out 0s;
    -o-transition: all .3s ease-out 0s;
    transition: all .3s ease-out 0s;
    width: auto;
    height: auto;
}

a {
    color: #0f161b;
    outline: 0;
    text-decoration: none;
    -webkit-transition: all .3s ease-out 0s;
    -moz-transition: all .3s ease-out 0s;
    -ms-transition: all .3s ease-out 0s;
    -o-transition: all .3s ease-out 0s;
    transition: all .3s ease-out 0s;

    &:focus {
        text-decoration: none;
        outline: 0;
        -webkit-box-shadow: none;
        -moz-box-shadow: none;
        -ms-box-shadow: none;
        -o-box-shadow: none;
        box-shadow: none;
        color: #fe5500;
    }

    &:hover {
        color: #fe5500;
        text-decoration: none;
    }
}

button {
    outline: 0;
    text-decoration: none;
    -webkit-transition: all .3s ease-out 0s;
    -moz-transition: all .3s ease-out 0s;
    -ms-transition: all .3s ease-out 0s;
    -o-transition: all .3s ease-out 0s;
    transition: all .3s ease-out 0s;

    &:hover {
        text-decoration: none;
    }

    &:focus {
        outline: 0;
    }
}

.btn {
    &:focus {
        text-decoration: none;
        outline: 0;
        -webkit-box-shadow: none;
        -moz-box-shadow: none;
        -ms-box-shadow: none;
        -o-box-shadow: none;
        box-shadow: none;
    }
}

.button {
    &:focus {
        text-decoration: none;
        outline: 0;
        -webkit-box-shadow: none;
        -moz-box-shadow: none;
        -ms-box-shadow: none;
        -o-box-shadow: none;
        box-shadow: none;
    }
}

input {
    &:focus {
        outline: 0;
    }
}

textarea {
    outline: 0;

    &:focus {
        outline: 0;
    }
}

h1,
h2,
h3,
h4,
h5,
h6 {
    font-family: 'behaBold';
    margin-top: 0;
    font-weight: 700;
    line-height: 1;
    text-transform: capitalize;
    margin-bottom: 0px;
}

.list-wrap {
    margin: 0;
    padding: 0;

    li {
        list-style: none;
    }
}

p {
    font-size: 16px;
    line-height: 1.45;
    font-weight: 400;
    margin-bottom: 15px;
}

hr {
    border-bottom: 1px solid #0f161b;
    border-top: 0 none;
    margin: 30px 0;
    padding: 0;
}

::-webkit-scrollbar-track {
    // box-shadow: inset 0 0 6px rgba(0, 0, 0, .3);
    // -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, .3);
    border-radius: 0px;
    background-color: #dddddd;
}

::-webkit-scrollbar {
    width: 6px;
    background-color: #dddddd;
}

::-webkit-scrollbar-thumb {
    border-radius: 0px;
    box-shadow: inset 0 0 6px rgba(0, 0, 0, .3);
    -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, .3);
    background-color: #999999;
}

::-moz-selection {
    background: #d15454;
    color: #0f161b;
    text-shadow: none;
    background: #d15454;
    color: #0f161b;
    text-shadow: none;
}

::selection {
    background: #d15454;
    color: #0f161b;
    text-shadow: none;
}

::-moz-placeholder {
    color: #0f161b;
    font-size: 16px;
    opacity: 1;
}

::placeholder {
    color: #0f161b;
    font-size: 16px;
    opacity: 1;
}

.fix {
    overflow: hidden;
}

.clear {
    clear: both;
}

#root {
    overflow: hidden;
}

// .main--area {
//     overflow-x: hidden;
// }

.container {
    padding-left: 15px;
    padding-right: 15px;
    max-width: 1190px;
}

.ss__section-title {
    position: relative;

    .ss__sub-title {
        display: block;
        text-transform: uppercase;
        font-size: 14px;
        letter-spacing: 2px;
        font-weight: 600;
        line-height: 1;
        margin: 0 0 7px;
        opacity: 1;
    }

    .title {
        font-size: 45px;
        font-weight: 800;
        letter-spacing: 1px;
        margin: 0 0;
    }

    &::after {
        content: "";
        display: block;
        width: 65px;
        height: 5px;
        margin: 20px auto 0;
    }
}

.ss__section-title.text-start {
    .title {
        line-height: 1.15;
    }

    &::after {
        margin: 20px 0 0;
    }
}

.transparent-header {
    position: fixed;
    left: 25px;
    top: 25px !important;
    width: calc(100% - 50px);
    z-index: 9;
    height: auto;

    .container {
        padding: 0px 0px 0px 0px;
        border: 2px solid #fe8e02;
        border-radius: 50px;
        color: #000;
        background-color: #fff;
        box-shadow: 2px 2px 0px 1px #824030;
        border-width: 2px 0px 0px 0px;
    }
}

.ss__border-button {
    font-family: 'behaBold';
}

.ss__header-main {
    -webkit-transition: all .4s ease-in-out;
    transition: all .4s ease-in-out;

    .mobile-nav-toggler {
        display: none;
        float: right;
        font-size: 26px;
        flex-direction: column;
        min-width: 45px;
        gap: 7px;
        align-items: center;
        cursor: pointer;
        position: relative;
        top: 18px;

        span {
            display: block;
            height: 3px;
            background-color: #fe8e02;
            -webkit-transition: all 0.3s ease-out 0s;
            -moz-transition: all 0.3s ease-out 0s;
            -ms-transition: all 0.3s ease-out 0s;
            -o-transition: all 0.3s ease-out 0s;
            transition: all 0.3s ease-out 0s;

            &:nth-child(1) {
                width: 26px;
            }

            &:nth-child(2) {
                width: 45px;
            }

            &:nth-child(3) {
                width: 26px;
                align-self: flex-end;
            }
        }
    }
}

.ss__menu-nav {
    display: -webkit-box;
    display: -moz-box;
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    justify-content: flex-start;
}

.ss__menu-navbar-wrap {
    display: -webkit-box;
    display: -moz-box;
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
    flex-grow: 1;

    ul {
        display: -webkit-box;
        display: -moz-box;
        display: -ms-flexbox;
        display: -webkit-flex;
        display: flex;
        padding: 0;
        flex-direction: row;
        flex-wrap: wrap;
        margin: 0 auto;

        li {
            display: block;
            position: relative;
            list-style: none;

            a {
                font-size: 16px;
                font-weight: 400;
                text-transform: capitalize;
                padding: 10px 15px;
                display: block;
                line-height: 1;
                position: relative;
                z-index: 1;
                letter-spacing: 1px;
                font-family: 'behaBold';
            }
        }
    }
}

.ss__menu-main {
    li.menu-item-has-children {
        .dropdown-btn {
            display: none;
        }
    }
}

.logo {
    a {
        display: block;
    }

    img {
        max-width: 60px;
        display: block;
        height: auto;
        transform: scale(1.2);
        transform-origin: center left;
    }
}

.ss__sticky-menu {
    position: fixed;
    left: 0;
    margin: auto;
    top: 0 !important;
    width: 100%;
    transform: translateY(-100%);
    z-index: 99;
    transition: all .4s ease;
    opacity: 0;
}

.ss__sticky-menu.ss__sticky-menu-show {
    -webkit-transform: unset;
    -moz-transform: unset;
    -ms-transform: unset;
    -o-transform: unset;
    transform: unset;
    opacity: 1;
}

.ss__mobile-menu {
    position: fixed;
    right: 0;
    top: 0;
    width: 300px;
    padding-right: 30px;
    max-width: 100%;
    height: 100%;
    z-index: 99;
    border-radius: 0;
    transition: all .3s cubic-bezier(.785, .135, .15, .86);
    -moz-transition: all .3s cubic-bezier(.785, .135, .15, .86);
    -webkit-transition: all .3s cubic-bezier(.785, .135, .15, .86);
    -ms-transition: all .3s cubic-bezier(.785, .135, .15, .86) e;
    -o-transition: all .3s cubic-bezier(.785, .135, .15, .86);
    -webkit-transform: translateX(101%);
    -moz-transform: translateX(101%);
    -ms-transform: translateX(101%);
    -o-transform: translateX(101%);
    transform: translateX(101%);

    .navbar-collapse {
        display: block !important;
    }

    .nav-logo {
        position: relative;
        padding: 30px 25px;
        text-align: left;

        img {
            max-width: 150px;
        }
    }

    .navigation {
        position: relative;
        display: block;
        width: 100%;
        float: none;
        margin: 0;
        padding: 0;

        li {
            position: relative;
            display: block;
            border-top: 1px solid #0f161b;

            >a {
                position: relative;
                display: block;
                line-height: 1.5;
                padding: 11px 60px 11px 25px;
                font-size: 15px;
                font-weight: 600;
                text-transform: uppercase;
                -webkit-transition: all .5s ease;
                -o-transition: all .5s ease;
                transition: all .5s ease;
                border: none;

                &::before {
                    content: "";
                    position: absolute;
                    left: 0;
                    top: 0;
                    height: 0;
                    -webkit-transition: all .5s ease;
                    -o-transition: all .5s ease;
                    transition: all .5s ease;
                }
            }

            ul {
                li {
                    >a {
                        margin-left: 20px;
                    }

                    ul {
                        li {
                            a {
                                margin-left: 40px;
                            }

                            ul {
                                li {
                                    a {
                                        margin-left: 60px;
                                    }
                                }
                            }
                        }
                    }
                }
            }

            >ul {
                display: none;

                >li {
                    >ul {
                        display: none;
                    }
                }
            }
        }

        li.current {
            >a {
                &::before {
                    height: 100%;
                }
            }
        }

        li.menu-item-has-children {
            .dropdown-btn {
                position: absolute;
                right: 18px;
                top: 6px;
                width: 32px;
                height: 32px;
                text-align: center;
                font-size: 16px;
                line-height: 32px;
                background: #0f161b;
                cursor: pointer;
                border-radius: 2px;
                -webkit-transition: all .5s ease;
                -o-transition: all .5s ease;
                transition: all .5s ease;
                z-index: 5;

                .plus-line {
                    position: absolute;
                    left: 50%;
                    top: 50%;
                    -webkit-transform: translate(-50%, -50%) rotate(0);
                    -ms-transform: translate(-50%, -50%) rotate(0);
                    transform: translate(-50%, -50%) rotate(0);
                    border-radius: 10px;
                    width: 12px;
                    height: 2px;
                    -webkit-transition: all .5s ease;
                    -o-transition: all .5s ease;
                    transition: all .5s ease;

                    &::after {
                        content: "";
                        position: absolute;
                        left: 50%;
                        top: 50%;
                        -webkit-transform: translate(-50%, -50%) rotate(0);
                        -ms-transform: translate(-50%, -50%) rotate(0);
                        transform: translate(-50%, -50%) rotate(0);
                        border-radius: 10px;
                        width: 2px;
                        height: 12px;
                        -webkit-transition: all .5s ease;
                        -o-transition: all .5s ease;
                        transition: all .5s ease;
                    }
                }
            }

            .dropdown-btn.open {
                .plus-line {
                    &::after {
                        display: none;
                    }
                }
            }
        }

        ul {
            padding: 0;
            margin: 0;

            li {
                a {
                    display: block;
                }

                ul {
                    li {
                        >a {
                            font-size: 16px;
                            margin-left: 20px;
                            text-transform: capitalize;
                        }
                    }
                }
            }
        }

        &:last-child {
            border-bottom: 1px solid #0f161b;
        }

        >li {
            >ul {
                >li {
                    &:first-child {
                        border-top: 1px solid #0f161b;
                    }
                }
            }
        }
    }

    .close-btn {
        position: absolute;
        right: 15px;
        top: 28px;
        line-height: 1.2;
        width: 28px;
        text-align: center;
        font-size: 20px;
        cursor: pointer;
        z-index: 10;
        transition: all .5s ease;
        padding: 4px;

        img {
            filter: brightness(15);
        }
    }

    .ss__social-links {
        ul {
            display: -webkit-box;
            display: -moz-box;
            display: -ms-flexbox;
            display: -webkit-flex;
            display: flex;
            position: relative;
            text-align: center;
            padding: 30px 20px 20px;
            align-items: center;
            justify-content: center;
            flex-wrap: wrap;
            gap: 12px;

            li {
                position: relative;
                display: block;

                a {
                    display: -webkit-box;
                    display: -moz-box;
                    display: -ms-flexbox;
                    display: -webkit-flex;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    width: 40px;
                    height: 40px;
                    position: relative;
                    font-size: 16px;
                    -webkit-transition: all .5s ease;
                    -o-transition: all .5s ease;
                    transition: all .5s ease;
                    border: 1px solid #ffffff;
                    border-radius: 3px;
                    -webkit-border-radius: 3px;
                    -moz-border-radius: 3px;
                    -ms-border-radius: 3px;
                    -o-border-radius: 3px;
                    padding: 8px;

                    &:hover {
                        background: #d15454;
                        color: #0f161b;
                    }
                }
            }
        }
    }
}

.ss__mobile-menu-bg {
    position: fixed;
    right: 0;
    top: 0;
    width: 100%;
    height: 100%;
    z-index: 2;
    transition: all .7s ease;
    -moz-transition: all .7s ease;
    -webkit-transition: all .7s ease;
    -ms-transition: all .7s ease;
    -o-transition: all .7s ease;
    opacity: 0;
    visibility: hidden;
    background: rgba(0, 0, 0, .5);
}

.ss__menu-box {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    max-height: 100%;
    overflow-y: auto;
    overflow-x: hidden;
    background: #824030;
    color: #ffffff;
    padding: 0 0;
    z-index: 5;
    box-shadow: -9px 0 14px 0 rgba(0, 0, 0, .06);

    a {
        color: #ffffff;
    }
}

.ss__mobile-menu-outer {
    .mobile-nav-toggler {
        position: relative;
        float: right;
        font-size: 40px;
        line-height: 50px;
        cursor: pointer;
        display: none;
        margin-right: 30px;
        top: 15px;
    }
}

.mobile-menu-visible {
    overflow: hidden;

    .ss__mobile-menu {
        -webkit-transform: translateX(0);
        -moz-transform: translateX(0);
        -ms-transform: translateX(0);
        -o-transform: translateX(0);
        transform: translateX(0);

        .close-btn {
            -webkit-transform: rotate(360deg);
            -moz-transform: rotate(360deg);
            -ms-transform: rotate(360deg);
            -o-transform: rotate(360deg);
            transform: rotate(360deg);
        }
    }

    .ss__mobile-menu-bg {
        opacity: 1;
        visibility: visible;
    }
}

// Banner Section Start
.ss__banner {
    padding-top: 130px;
    margin-bottom: 80px;
    overflow: hidden;
    text-align: center;
    position: relative;
    background-image: url('../images/herobg.jpg');
    background-position: center bottom;
    background-repeat: no-repeat;
    background-size: cover;

    &::before,
    &::after {
        content: "";
        position: absolute;
        background-image: url('../images/hanging.webp');
        background-repeat: no-repeat;
        background-size: contain;
        top: 85px;
        z-index: 0;
        width: 255px;
        height: 265px;
    }

    &::before {
        background-position: left;
        left: 0px;
        transform: scaleX(1);
    }

    &::after {
        background-position: right;
        right: 0px;
        transform: scaleX(-1);
    }

    h1 {
        color: #fe8e02;
    }


    .ss__banner-content {
        text-align: center;
        display: block;

        img {
            max-width: 150px;
            margin-bottom: 15px;
        }

        h1 {
            font-size: 80px;
            letter-spacing: 0px;
            text-shadow: 2px 2px #824030;
        }

        .lottieImg {
            margin-right: 20px;

            img {
                max-width: 130px;
                display: block;
            }
        }
    }
}

.header-btn .ss__border-button {
    font-family: "behaRegular2";
}

.roadmapTree {
    font-family: "behaRegular2";
    text-align: center;
    display: flex;
    align-items: center;
    justify-content: space-between;
    background-color: #fe8e02;
    max-width: 900px;
    margin: 100px auto 30px;
    border-radius: 50px;
    padding: 5px 30px;
    height: 20px;
    border: 2px solid #824030;

    .item {
        position: relative;
        margin: 0px 5px;

        &::before {
            content: "";
            position: absolute;
            top: 0px;
            left: 50%;
            width: 10px;
            background-image: url('../images/treeline.webp');
            background-repeat: no-repeat;
            background-position: center;
            background-size: contain;
            height: 110px;
            border-radius: 10px;
            transform: translate(-50%, -50%);
            z-index: 1;
        }

        span {
            display: block;
            position: relative;
            top: 42px;
            font-size: 18px;
            font-weight: bold;
            z-index: 2;
        }

        strong {
            display: block;
            position: relative;
            bottom: 40px;
            font-size: 14px;
            font-weight: 400;
            background-color: #faf0e4;
            z-index: 2;
            line-height: 1.2;
            padding: 1px 8px;
            border-radius: 10px;
            border: 1px solid #9f4d2b;
            min-width: 56px;
        }

        &::after {
            content: "";
            position: absolute;
            top: -15px;
            right: 25px;
            width: 140px;
            height: 110px;
            background-image: url('../images/push.webp');
            background-repeat: no-repeat;
            background-position: center;
            background-size: contain;
            z-index: 1;
            display: none;
        }

        &.push {
            &::after {
                display: block;
            }
        }
    }
}

.contractAddress {
    margin: -50px auto 200px;

    .contractAddressInner {
        background-color: #f3ceb1;
        max-width: 470px;
        position: relative;
        display: flex;
        align-items: center;
        justify-content: space-between;
        margin: 0 auto;
        border-radius: 50px;
        padding: 0px 0px 0px 10px;
        border: 2px solid #7f4327;
        width: 100%;

        &::before {
            content: "";
            position: absolute;
            bottom: 25px;
            left: 50%;
            width: calc(100%);
            height: 90px;
            background-image: url('../images/grass.svg');
            background-position: bottom center;
            background-size: cover;
            transform: translateX(-50%);
            z-index: -1;
            border-radius: 50px 70px 0px 0px;
        }
    }

    input {
        background-color: transparent;
        border: none;
        outline: none;
        box-shadow: none;
        padding: 10px 5px;
        color: #000000;
        font-size: 16px;
        width: 100%;
    }

    svg {
        width: 45px;
        cursor: pointer;
    }

    .copied {
        position: absolute;
        top: calc(100% + 7px);
        right: 10px;
        color: #824030;
        transition: all 0.3s ease-in-out;
    }
}

.bordTitle {
    margin-top: 80px;

    .bordTitleInner {
        position: relative;
        width: fit-content;
        margin: 0px auto;
        right: 100px;

        .potImg {
            position: absolute;
            top: 40px;
            left: 230px;
            width: 290px;
            height: 140px;
            z-index: -1;
            cursor: pointer;
            display: block;

            img {
                width: 100%;
                height: 100%;
                animation: zoominout 1s alternate infinite;
                object-fit: contain;
            }
        }

        @keyframes zoominout {
            to {
                transform: scale(0.9);
            }

            from {
                transform: scale(1);
            }
        }

        .priceBord {
            max-width: 210px;
        }

        span {
            position: absolute;
            top: 50px;
            left: 50%;
            transform: translateX(-50%) rotate(-10deg);
            font-family: 'behaRegular2';
            color: #ffeca1;
            font-weight: bold;
            font-size: 24px;
            text-shadow: 1px 1px 1px #824030;
        }
    }
}

.whoisMain {
    margin-bottom: 100px;

    .row {
        align-items: center;
    }

    .infobox {
        background-color: #fff;
        border: solid #000;
        border-width: 3px 3px 6px;
        border-radius: 50px;
        flex-flow: column;
        justify-content: flex-start;
        align-items: center;
        padding: 25px;
        display: flex;
        position: relative;
        width: 100%;

        h2 {
            text-align: center;
            font-family: 'behaRegular';
            font-size: 70px;
            line-height: 1.1;
        }

        p {
            color: #000;
            text-align: center;
            font-family: 'behaRegular2';
            font-weight: 700;
        }
    }

    .imgbox {
        padding: 5%;
    }
}

// Banner Section End
@keyframes topbottom {
    to {
        top: 45%;
    }

    from {
        top: 55%;
    }
}

.slerfsaviournomicsMain {
    padding: 100px 0px 100px;
    position: relative;

    &::before {
        content: "";
        position: absolute;
        background-image: url('../images/slerficon1.svg');
        background-position: right;
        background-repeat: no-repeat;
        background-size: contain;
        bottom: 350px;
        right: 0px;
        z-index: 0;
        width: 240px;
        height: 150px;
        transform: scaleX(1);
        animation: topbottom1 5s alternate infinite;
    }

    .infobox {
        h2 {
            text-align: center;
            font-family: "behaRegular";
            font-size: 70px;
            line-height: 1.1;
        }
    }

    .infoboxInner {
        margin: 50px auto 0px;
        width: auto;
        height: auto;
        background-color: #fff;
        border: 3px solid #000;
        border-radius: 20px;
        padding: 30px;
        display: block;
        position: relative;
        max-width: 520px;

        .item {
            background-color: #fe8e02;
            border: solid #000;
            border-width: 3px 3px 6px;
            border-radius: 13px;
            padding: 10px;
            margin: 0px 0px 15px;
            color: #ffffff;
            text-align: center;
            font-family: 'behaRegular2';

            h3 {
                font-family: 'behaRegular2';
                font-size: 20px;
            }

            p {
                margin: 0px;
                font-size: 16px;
                opacity: 0.9;

                a {
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    margin-top: 5px;
                    text-decoration: underline;
                    color: #ffffff !important;

                    span {
                        color: #ffffff;
                    }

                    svg {
                        height: 22px;
                        width: auto;
                        margin-left: 6px;
                    }
                }
            }
        }
    }
}

.topaddressMain {
    padding: 100px 0px 170px;
    position: relative;

    &::before {
        content: "";
        position: absolute;
        background-image: url('../images/slerficon2.svg');
        background-position: right;
        background-repeat: no-repeat;
        background-size: contain;
        bottom: 350px;
        right: -10px;
        z-index: 0;
        width: 280px;
        height: 150px;
        transform: scaleX(1);
        animation: topbottom1 5s alternate infinite;
    }

    .infobox {
        h2 {
            text-align: center;
            font-family: "behaRegular";
            font-size: 70px;
            line-height: 1.1;
        }
    }


    .infoboxInner {
        margin: 50px auto 0px;
        display: block;
        position: relative;

        .item {
            background-color: #fad5a9;
            border: solid #000;
            border-width: 1px 1px 2px;
            border-radius: 13px;
            padding: 10px;
            margin: 0px 0px 10px;
            color: #3c1809;
            text-align: center;
            font-family: sans-serif;

            h3 {
                font-family: 'behaRegular2';
                font-size: 20px;
            }

            a {
                margin: 0px;
                font-size: 16px;
                line-height: 1.1;
                display: block;
            }
        }
    }
}

.lineSectionbar {
    position: relative;
    padding: 0px;
    width: 100%;
    height: 100px;

    &::before {
        content: "";
        position: absolute;
        background-image: url('../images/treebranch.svg');
        background-position: center;
        background-repeat: no-repeat;
        background-size: cover;
        top: -170px;
        left: 0px;
        width: 100%;
        height: 170px;
        z-index: 1;
        transform: scaleY(-1);
    }

    &::after {
        content: "";
        position: absolute;
        background-image: url('../images/hanging.webp');
        background-position: left;
        background-repeat: no-repeat;
        background-size: contain;
        top: -63px;
        left: 0px;
        width: 210px;
        height: 210px;
        z-index: -1;
    }
}

.titleMain {
    max-width: 600px;
    margin: 0 auto;
}

footer {
    background-color: #f3ceb1;
    border-top: 4px solid #7c3d2e;
    font-family: 'behaRegular2';
    text-align: left;

    p {
        a {
            color: #0000D1;
        }
    }
}

.ss__footer-social {
    display: flex;
    align-items: center;
    justify-content: flex-end;

    a {
        display: block;
        background-color: #824030;
        margin-left: 8px;
        width: 42px;
        height: 42px;
        border-radius: 7px;
        padding: 8px;
    }
}

.ss__menu-action {
    margin-right: 15px;
    font-family: 'behaRegular2';

    .header-btn {
        &::before {
            box-shadow: inset 0 3px 7px 0 #d15454;
            opacity: .1;
        }
    }
}

@media (prefers-reduced-motion:no-preference) {
    :root {
        scroll-behavior: smooth;
    }
}

@media (min-width:1649px) {

    .ss__banner::before,
    .ss__banner::after {
        top: 0px;
    }
}

@media (max-width:1500px) {
    .ss__menu-navbar-wrap {
        ul {
            margin: 0 auto;
        }
    }

    .topaddressMain::before {
        bottom: 0px;
    }
}

@media (max-width:1199px) {
    .container {
        max-width: 960px;
    }

    .ss__header-main {
        .mobile-nav-toggler {
            display: flex;
        }
    }

    .ss__menu-nav {
        justify-content: space-between;
    }

    .ss__menu-action {
        margin-right: 15px;
        font-family: 'behaRegular2';

        .header-btn {
            &::before {
                box-shadow: inset 0 3px 7px 0 #d15454;
                opacity: .1;
            }
        }
    }

    .ss__banner::before,
    .ss__banner::after {
        width: 185px;
        height: 195px;
    }

    .whoisMain .infobox h2,
    .slerfsaviournomicsMain .infobox h2,
    .topaddressMain .infobox h2 {
        font-size: 52px;
    }

    .ss__banner {
        background-size: contain;
    }

    .topaddressMain .infoboxInner .item a {
        margin: 0px;
        font-size: 14px;
    }

    .transparent-header {
        .container {
            padding: 0px 20px 0px 0px;
        }
    }
}

@media (max-width:991px) {
    .container {
        max-width: 720px;
    }

    .lineSectionbar::after {
        width: 150px;
        height: 150px;
    }

    .whoisMain .imgbox img {
        width: 500px;
    }

    .slerfsaviournomicsMain::before {
        bottom: unset;
        width: 180px;
        height: 125px;
        top: 30px;
    }

    .topaddressMain::before {
        bottom: unset;
        top: 65px;
        width: 220px;
        height: 110px;
    }

    .topaddressMain {
        padding: 100px 0px 50px;
    }

    footer p {
        font-size: 14px;
    }
}

@media (max-width:767px) {
    .container {
        max-width: 100%;
    }

    .ss__header-main {
        .mobile-nav-toggler {
            margin-top: 0;
        }
    }

    .roadmapTree .item strong {
        font-size: 12px;
        line-height: 1;
        padding: 1px 6px;
        min-height: 33px;
        display: flex;
        align-items: center;
        justify-content: center;
        bottom: 50px;
    }

    .ss__banner::before,
    .ss__banner::after {
        width: 135px;
        height: 150px;
    }

    .ss__banner .ss__banner-content h1 {
        font-size: 60px;
    }

    .roadmapTree .item::after {
        top: -10px;
        right: 19px;
        width: 90px;
        height: 65px;
    }

    .roadmapTree .item span {
        top: 24px;
        font-size: 16px;
    }

    .lineSectionbar::before {
        top: -140px;
        width: 100%;
        height: 100px;
    }

    .lineSectionbar::after {
        top: -80px;
    }

    .slerfsaviournomicsMain::before {
        top: 10px;
    }

    .whoisMain .infobox h2,
    .slerfsaviournomicsMain .infobox h2,
    .topaddressMain .infobox h2 {
        font-size: 38px;
    }

    p {
        font-size: 14px;
    }

    footer {
        text-align: center;
    }

    .ss__footer-social {
        justify-content: center;
        margin-bottom: 15px;
    }

    .whoisMain .infobox p {
        text-align: justify;
    }
}

@media (max-width:575px) {

    .ss__banner::before,
    .ss__banner::after {
        width: 100px;
        height: 110px;
        top: 60px;
    }

    .ss__banner {
        padding-top: 85px;
    }

    .contractAddress {
        margin: -50px auto 70px;
    }

    .logo img {
        max-width: 45px;
    }

    .ss__header-main .mobile-nav-toggler {
        min-width: 25px;
        gap: 5px;
        top: 12px;
    }

    .ss__header-main .mobile-nav-toggler span:nth-child(1) {
        width: 20px;
    }

    .ss__header-main .mobile-nav-toggler span:nth-child(2) {
        width: 35px;
    }

    .ss__header-main .mobile-nav-toggler span:nth-child(3) {
        width: 20px;
    }

    .transparent-header {
        top: 15px !important;
    }

    .ss__banner .ss__banner-content img {
        max-width: 130px;
        margin-bottom: 10px;
    }

    .ss__banner .ss__banner-content h1 {
        font-size: 52px;
    }

    .roadmapTree {
        padding: 5px 0px;
        max-width: 390px;
    }

    .roadmapTree .item::after {
        right: 20px;
    }

    .whoisMain {
        margin-bottom: 50px;
    }

    .slerfsaviournomicsMain .infoboxInner,
    .topaddressMain .infoboxInner {
        margin: 30px auto 0px;
        padding: 25px 25px 10px;
    }

    .slerfsaviournomicsMain .infoboxInner .item h3,
    .topaddressMain .infoboxInner .item h3 {
        font-size: 16px;
    }

    .slerfsaviournomicsMain .infoboxInner .item p,
    .topaddressMain .infoboxInner .item a {
        font-size: 14px;
    }

    .titleMain {
        max-width: 320px;
    }

    .topaddressMain {
        padding: 70px 0px 30px;
    }

    .lineSectionbar {
        height: 70px;
    }

    .topaddressMain::before {
        top: 15px;
    }

    .bordTitle .bordTitleInner .potImg {
        top: 50px;
        left: 160px;
    }
}

@media (max-width:425px) {
    .ss__banner .ss__banner-content h1 {
        font-size: 44px;
    }

    .ss__banner::before,
    .ss__banner::after {
        width: 85px;
        height: 95px;
    }

    .roadmapTree .item strong {
        font-size: 11px;
        padding: 1px 4px;
        letter-spacing: -0.3px;
    }

    .roadmapTree .item {
        margin: 0px 2px;
    }

    .roadmapTree .item span {
        font-size: 14px;
    }

    .roadmapTree {
        max-width: 290px;
    }

    .roadmapTree .item::after {
        right: 23px;
    }

    .bordTitle .bordTitleInner .priceBord {
        max-width: 160px;
    }

    .bordTitle {
        margin-top: 60px;
    }

    .bordTitle .bordTitleInner span {
        top: 35px;
        font-size: 20px;
    }

    .slerfsaviournomicsMain::before {
        width: 150px;
        height: 115px;
    }

    .topaddressMain::before {
        width: 160px;
        height: 90px;
    }

    .slerfsaviournomicsMain .infoboxInner,
    .topaddressMain .infoboxInner {
        padding: 15px 15px 5px;
    }

    .slerfsaviournomicsMain .infoboxInner .item h3,
    .topaddressMain .infoboxInner .item h3 {
        font-size: 14px;
    }

    .slerfsaviournomicsMain .infoboxInner .item p,
    .topaddressMain .infoboxInner .item a {
        font-size: 12px;
    }

    .bordTitle .bordTitleInner {
        right: 70px;
    }

    .bordTitle .bordTitleInner .potImg {
        top: 30px;
        left: 160px;
        height: 100px;
        width: 140px;
    }
}

@media only screen and (min-width:576px) and (max-width:767px) {
    .container {
        max-width: 540px;
    }
}